/* You can add global styles to this file, and also import other style files */
.lock { position:relative; }
.lock::before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    z-index: 1000;
    width:100%;
    height:100%;
    border-radius:5px;
    background: rgba(0,0,0,0.75) url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIzMCIgdmlld0JveD0iMCAwIDIxNy4yNDIgMzAwIj4KICA8cGF0aCBpZD0ibG9jayIgZD0iTS01MTMtMTUwLjYyMWExMDguMzUsMTA4LjM1LDAsMCwxLDM2LjIwNy04MC45NjJ2LTM4QTcyLjQxMyw3Mi40MTMsMCwwLDEtNDA0LjM4LTM0MmE3Mi40MTMsNzIuNDEzLDAsMCwxLDcyLjQxNCw3Mi40MTR2MzhhMTA4LjM1LDEwOC4zNSwwLDAsMSwzNi4yMDcsODAuOTYyQTEwOC42MjEsMTA4LjYyMSwwLDAsMS00MDQuMzgtNDIsMTA4LjYyMSwxMDguNjIxLDAsMCwxLTUxMy0xNTAuNjIxWm04OS40My03LjgxOGEyMC42OSwyMC42OSwwLDAsMCw4Ljg0NiwyNS43MTV2MjguNjU1QTEwLjM0NSwxMC4zNDUsMCwwLDAtNDA0LjM4LTkzLjcyNGExMC4zNDUsMTAuMzQ1LDAsMCwwLDEwLjM0NS0xMC4zNDV2LTI4LjY1NWEyMC42ODksMjAuNjg5LDAsMCwwLDkuNzM0LTIzLjExNSwyMC40MzUsMjAuNDM1LDAsMCwwLTE1LTE0Ljg2NSwyMC42OTMsMjAuNjkzLDAsMCwwLTUuMTA4LS42NDJBMjAuNywyMC43LDAsMCwwLTQyMy41NzEtMTU4LjQzOVptNzAuOTE1LTg3Ljc2OHYtMjMuMzhBNTEuNzI0LDUxLjcyNCwwLDAsMC00MDQuMzgtMzIxLjMxLDUxLjcyNCw1MS43MjQsMCwwLDAtNDU2LjEtMjY5LjU4NnYyMy4zOGguMDg5YTEwOC4xMjIsMTA4LjEyMiwwLDAsMSw1MS42MzYtMTMuMDM0LDEwOC4xMjIsMTA4LjEyMiwwLDAsMSw1MS42MzYsMTMuMDM0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTEzLjAwMSAzNDIpIiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPgo=') 50% 50% no-repeat;
}

/* select  */
.ng-select {position:relative; }
.ng-select > button {width:100%;}
.ng-select > button svg {transition:all 0.3s; }
.ng-select.on > button svg {transform:rotate(90deg); }
.ng-select ul {z-index:10; position:absolute; left:0; top:100%; visibility: hidden; opacity:0; width: 100%; max-height:260px; margin-top:-10px; transition:all 0.3s; }
.ng-select.on ul {visibility: visible; opacity:1; margin-top:0; }
.ng-select ul li {cursor:pointer; border-radius:4px; padding-left:8px; }
.ng-select ul li:not(.nohover):hover {background-color:var(--background-color); }
.ng-select ul li.on {position:relative; border:1px solid #3bc11766;}
.ng-select ul li.on:after {content: ""; position: absolute; width: 0.5rem; height: 0.5rem; border-radius: 50%; top: 50%; margin-top: -0.25rem; right: 0.625rem; background-color: var(--primary-color); box-shadow: 0 0 0 0.3125rem #3bc11726;}
.ng-select[data-select="count"] input[type="checkbox"]:checked + label {color:var(--text-color); }
.ng-select[data-select="count"] ul {max-height:400px; }
.ng-select.top.on > button svg {transform:rotate(-90deg); } 
.ng-select.top ul {top:auto; bottom:100%;}
.ng-select[data-select="add"] ul li.on {border:0px solid transparent; }
.ng-select[data-select="add"] ul li.on:after {display:none;}

.ng-select .date {padding-left:0 !important; }
.ng-select .date.on {border:0 none !important; }
.ng-select .date.on:after {display:none !important; }
.ng-select .date input {width:100%; height:32px !important; padding:0 23px 0 8px; background-color:transparent !important; color:var(--sub-text-color); cursor:pointer; font-size:13px;}
.ng-select .date input:not(:placeholder-shown) {border:1px solid #3bc11766; }
.ng-select .date input::placeholder {color:var(--sub-text-color) }
.ng-select .date input::-webkit-input-placeholder {color:var(--sub-text-color)}

.ng-select2 {position:relative; }
.ng-select2 > button {width:100%;}
.ng-select2 > button svg.arr {transition:all 0.3s; }
.ng-select2.on > button svg.arr {transform:rotate(90deg); }
.ng-select2 > div {z-index:10; position:absolute; left:0; top:100%; visibility: hidden; opacity:0; width: 100%; margin-top:30px; transition:all 0.3s; }
.ng-select2 > div.right {left:auto; right:0; }
.ng-select2.on > div {visibility: visible; opacity:1; margin-top:0; }
.ng-select2 ul li {cursor:pointer; border-radius:4px; padding-left:8px; }
.ng-select2 ul li:hover {background-color:var(--background-color); }
.ng-select2 ul li.on {position:relative; border:1px solid #3bc11766;}

.dm-date {
    display: flex;
    flex-basis: 100%;
    align-items: center;
}

.dm-date::before {
    content: "";
    flex-grow: 1;
    margin: 0 8px;
    background: #656e78;
    height:1px;
    font-size: 0;
    line-height: 0;
}

.dm-date::after {
    content: "";
    flex-grow: 1;
    margin: 0 8px;
    background: #656e78;
    height:1px;
    font-size: 0;
    line-height: 0;
}